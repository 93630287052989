import {
  LoginRNRRouteData,
  RnrRouteData,
  getLoginRNRRouteData,
} from "~/server/data/login_rnr_route_data";
import { cache, createAsync, A } from "@solidjs/router";
import { Accessor, createMemo, Match, onMount, Switch } from "solid-js";
import {
  loginAsset,
  squidLoginHeaderMobileBg,
  squidLogo,
} from "~/assets/assets";
import LoginForm from "~/components/login-rnr/login_form";
import { rnrEventManager } from "~/data/events";
import { getClientIdFromCookie } from "~/utils/common";
import { config } from "~/data/config";
import LoginRnrComponentV1 from "~/components/login-rnr/login_rnr_component";
import LoginRnrAxisComponent from "~/components/login-rnr/login_rnr_axis_component";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";
import { Spacer } from "~/widgets/spacer";
import LoginRaisoniComponent from "~/components/login-rnr/login_rnr_raisoni_component/login_rnr_raisoni_component";

const getLoginRNRRouteData$C = cache(getLoginRNRRouteData, "login");

export default function LoginRNRRoute() {
  const routeData: Accessor<LoginRNRRouteData | undefined> = createAsync(() =>
    getLoginRNRRouteData$C()
  );

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
  });

  const isClientAxis = createMemo(() => {
    const data = routeData();
    return data?.mode === "rnr" && data?.clientId === config.axisClientId;
  });

  const isClientRaisoni = createMemo(() => {
    const data = routeData();
    return (
      data?.mode === "rnr" &&
      data?.clientId.includes(config.clientIdPrefix.raisoni)
    );
  });

  const mode = createMemo(() => {
    return routeData()?.mode;
  });
  return (
    <Switch>
      <Match when={isClientAxis()}>
        <LoginRnrAxisComponent
          routeData={routeData as Accessor<RnrRouteData>}
        />
      </Match>
      <Match when={isClientRaisoni()}>
        <LoginRaisoniComponent
          routeData={routeData as Accessor<RnrRouteData>}
        />
      </Match>
      <Match when={mode() === "squid"}>
        <LoginSquid routeData={routeData} />
      </Match>
      <Match when={true}>
        <LoginRnrComponentV1
          isClientAxis={isClientAxis}
          routeData={routeData}
        />
      </Match>
    </Switch>
  );
}

function LoginSquid(props: {
  routeData: Accessor<LoginRNRRouteData | undefined>;
}) {
  return (
    <div class="h-full w-full sm:flex">
      <div class="relative hidden h-full w-[50%] bg-gray-400 sm:block">
        <HubbleImage
          src={loginAsset}
          alt="login-asset"
          errorImage=""
          class="h-full w-full object-cover"
        />
        <div class="absolute left-14 top-10 flex flex-col items-start justify-start ">
          <div class="flex">
            <HubbleImage
              src={squidLogo}
              alt="logo-image"
              errorImage=""
              class="mr-1 h-10 w-10"
            />
            <div class="flex flex-col items-start justify-start">
              <div class="text-bold text-white">SQUID</div>
              <div class="text-f12Bold text-white">By Hubble</div>
            </div>
          </div>
          <div class="mt-11 w-[330px] text-h1 text-white">
            For all your gift card needs
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              450+ brands
            </div>
          </div>
          <div class="mt-4 flex items-center justify-start">
            <PhosphorIcon
              name="check"
              fontSize={18}
              class="text-baseTertiaryDark"
            />
            <div class="ml-[6px] text-normal text-baseTertiaryDark">
              Best deals & prices
            </div>
          </div>
        </div>
      </div>
      <div class="flex h-full w-full flex-col items-center justify-start align-middle sm:w-[50%] sm:justify-center">
        <div class="mb-6 w-full sm:hidden">
          <img
            class="absolute left-0 right-0 top-0 w-full"
            src={squidLoginHeaderMobileBg}
            alt=""
          />
          <div class="absolute left-4 top-3.5 flex items-start justify-start gap-1">
            <HubbleImage
              src={squidLogo}
              alt="logo-image"
              errorImage=""
              class="h-8 w-8"
            />
            <div class="flex flex-col items-start justify-start">
              <div class="text-mediumBold text-white">SQUID</div>
              <div class="text-smallBold text-white">By Hubble</div>
            </div>
          </div>
        </div>
        <Spacer height={60} />
        <div class="mb-[19px] w-full px-4 text-start text-h4 text-textDark sm:mb-6 sm:w-[356px] sm:px-0 sm:text-h2 sm:text-black">
          Login
        </div>
        <div class="flex h-52 w-full items-center justify-center">
          <LoginForm
            clientId={() => {
              const data = props.routeData();
              return data && "clientId" in data ? data.clientId : undefined;
            }}
            clientSecret={() => {
              const data = props.routeData();
              return data && "clientSecret" in data
                ? data.clientSecret
                : undefined;
            }}
            authMethod={() => props.routeData()?.authMethod!}
            mode={() => props.routeData()?.mode!}
          />
        </div>
        <div class="mt-6 hidden text-medium text-textNormal sm:block">
          Don’t have an account?{" "}
          <A href="/register">
            <b class="cursor-pointer">Register</b>
          </A>
        </div>
      </div>
    </div>
  );
}
