import { Accessor } from "solid-js";
import { RnrRouteData } from "~/server/data/login_rnr_route_data";
import { BrandingRow } from "~/widgets/branding";
import LoginForm from "../login_form";
import { RaisoniHeader } from "./raisoni_login_header";

export default function LoginRaisoniComponent(props: {
  routeData: Accessor<RnrRouteData | undefined>;
}) {
  return (
    <div class="flex h-screen w-full flex-col ">
      <div
        style={{
          "background-size": "100% 100%",
        }}
        class={`flex w-full flex-1 flex-col items-center bg-[url(https://assets.myhubble.money/web-assets/corp-gifting/raisoni-login-bg-mobile.webp)] bg-cover bg-no-repeat  sm:bg-[url(https://assets.myhubble.money/web-assets/corp-gifting/raisoni-login-bg-desktop.webp)] sm:bg-contain sm:px-0 `}
      >
        <RaisoniHeader />
        <div class="mt-[25px]"></div>
        <WelcomeText />
        <div class="mt-8"></div>
        <div class="flex w-full items-center justify-center px-4">
          <LoginContainer />
        </div>
      </div>
      <BrandingRow />
    </div>
  );
  function LoginContainer() {
    return (
      <div class="flex w-full max-w-[376px] flex-col items-start justify-start rounded-[20px] bg-white py-5">
        <LoginForm
          clientId={() => props.routeData()?.clientId!}
          clientSecret={() => props.routeData()?.clientSecret!}
          authMethod={() => props.routeData()?.authMethod!}
          mode={() => props.routeData()?.mode!}
          placeholderText="Email ID associated with Raisoni"
        />
      </div>
    );
  }

  function WelcomeText() {
    const purpleFontStyle =
      "text-[32px] sm:text-[44px] font-semibold leading-[1.3] tracking-[-0.64px] sm:tracking-[-0.88px] text-white";
    return (
      <div
        class={`flex flex-col items-center justify-center ${purpleFontStyle}`}
      >
        <div class="mt-7 sm:mt-4"></div>
        <span class="text-bold font-medium text-successDark">Welcome</span>
        <div class="mt-2"></div>
        <span class="flex">Enjoy exclusive</span>
        <span class="flex">“Raisonian discount”</span>
        <span class="flex">on 450+ brands</span>
      </div>
    );
  }
}
