import { raisoniLogoWhite } from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { createAutoAnimate } from "@formkit/auto-animate/solid";
import { classNames } from "~/utils/etc";
import {
  Accessor,
  createSignal,
  JSX,
  onCleanup,
  onMount,
  Setter,
  Show,
} from "solid-js";
import { openIntercom } from "~/utils/third_party/intercom";

type RaisoniHeaderButton = {
  text: string;
  onClick: () => void;
};

export function RaisoniHeader() {
  const raisoni_header_buttons: RaisoniHeaderButton[] = [
    {
      text: "About",
      onClick: () => {
        window.open("https://alumni.raisoni.net/about.php", "_blank");
      },
    },
    {
      text: "News & Stories",

      onClick: () => {
        window.open("https://alumnii.raisoni.net/campusfeed?cat=all", "_blank");
      },
    },
    {
      text: "Events",
      onClick: () => {
        window.open(
          "https://alumnii.raisoni.net/events?cat=all&amp;search=%7B%7D",
          "_blank"
        );
      },
    },
    {
      text: "Community",
      onClick: () => {
        window.open("https://alumnii.raisoni.net/members", "_blank");
      },
    },
    {
      text: "Contact support",
      onClick: () => {
        openIntercom();
      },
    },
  ];
  return (
    <div
      class="flex w-full flex-row justify-between px-4 py-[28px] sm:px-8"
      style={{
        background:
          "linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)",
      }}
    >
      <img src={raisoniLogoWhite} class="h-[35px]"></img>
      <div class="hidden flex-row gap-5 sm:flex ">
        {raisoni_header_buttons.map((button, index) => (
          <HeaderButton text={button.text} onClick={button.onClick} />
        ))}
      </div>
      <div class="sm:hidden">
        <DropDownCta />
      </div>
    </div>
  );

  function HeaderButton(props: {
    text: string;
    onClick: () => void;
    class?: string;
  }) {
    return (
      <div
        class={`flex cursor-pointer items-center justify-center text-mediumBold text-white ${props.class}`}
        onClick={props.onClick}
      >
        {props.text}
      </div>
    );
  }

  function DropDownCta() {
    const [isDropdownOpen, setIsDropdownOpen] = createSignal(false);

    const closeDropdownCallback = (e: any) => {
      if (isDropdownOpen()) {
        setIsDropdownOpen(false);
      }
    };

    onMount(() => {
      document.addEventListener("click", closeDropdownCallback);
      onCleanup(() =>
        document.removeEventListener("click", closeDropdownCallback)
      );
    });

    return (
      <div class="relative">
        <div
          class="cursor-pointer"
          onClick={async () => {
            await new Promise((resolve) => setTimeout(resolve, 100));
            setIsDropdownOpen?.(!isDropdownOpen?.());
          }}
        >
          <Show
            when={isDropdownOpen?.()}
            fallback={
              <PhosphorIcon
                name="list"
                fontSize={28}
                size={"bold"}
                class="text-white"
              />
            }
          >
            <PhosphorIcon
              name="x"
              fontSize={28}
              size={"bold"}
              class="text-white"
            />
          </Show>
        </div>

        <DropDownMenu
          isDropdownOpen={isDropdownOpen!}
          setIsDropdownOpen={setIsDropdownOpen!}
          isLoggedIn={false}
          isClientAxis={() => true}
        />
      </div>
    );
  }

  function DropDownMenu(props: {
    setIsDropdownOpen: Setter<boolean>;
    isDropdownOpen: Accessor<boolean>;
    isLoggedIn: boolean;
    showBusinessMenu?: boolean;
    isClientAxis?: () => boolean;
  }) {
    const [parent] = createAutoAnimate();

    return (
      <div
        class={classNames(
          `absolute right-[0px] top-0 flex w-[205px] flex-col rounded-xl bg-baseTertiaryLight `
        )}
        classList={{
          "border border-neutral-200": props.isDropdownOpen(),
        }}
      >
        <div ref={parent}>
          <Show when={props.isDropdownOpen()}>
            <div class="flex flex-col rounded-xl bg-baseTertiaryLight  px-3 py-1">
              {raisoni_header_buttons.map((button, index) => (
                <>
                  <MenuItem
                    title={button.text}
                    onClick={() => {
                      button.onClick();
                      props.setIsDropdownOpen?.(false);
                    }}
                  />
                  <Show when={index < raisoni_header_buttons.length - 1}>
                    <div class="h-[1px] w-full bg-baseTertiaryMedium"></div>
                  </Show>
                </>
              ))}
            </div>
          </Show>
        </div>
      </div>
    );

    function MenuItem(props: {
      title: string;
      iconUrl?: string;
      onClick: () => void;
      iconBuilder?: () => JSX.Element;
      darkIconBuilder?: () => JSX.Element;
    }) {
      return (
        <div
          class=" cursor-pointer items-center justify-start py-[9px] "
          onClick={props.onClick}
        >
          <div class="flex w-full grow text-subtitleSmall  text-textDark">
            {props.title}
          </div>
        </div>
      );
    }
  }
}
